import React from 'react';
import { isEmpty } from 'lodash';
import { useCreationViewContext } from '../context/creation-view-context';
import { Wrapper } from 'components/controls/controls.styled';

export default function DefaultStepRenderer() {
    const {
        state: { sideBarLength, loadingStates },
    } = useCreationViewContext();

    if (!isEmpty(loadingStates)) {
        return <></>;
    }

    const message = sideBarLength ? (
        <p>Please select a step from the left menu.</p>
    ) : (
        <>
            <p>You do not have access to this entity.</p>
            <p>
                Please contact&nbsp;
                <a href='mailto:BDGLegalEntityManagement@Blackstone.com'>BDGLegalEntityManagement@Blackstone.com</a>
                &nbsp;to request for access.
            </p>
        </>
    );

    return <Wrapper>{message}</Wrapper>;
}
