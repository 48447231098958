import styled from 'styled-components';

export const FormControl = styled.div<{ height?: number }>`
    display: flex;
    flex-direction: column;
    &.form {
        height: ${({ height }) => `${height ?? 83}px`};
    }
    &.dashboard {
        & > div > div {
            background: none;
        }
    }
`;

export const Wrapper = styled.div`
    min-width: 400px;
    max-width: 1100px;
    margin: 200px auto;
    text-align: center;
    padding-right: 80px;
    a {
        color: #6ea3f4;
        text-decoration: underline;
    }
`;
