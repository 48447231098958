import { TextBlock, WaitingIndicator } from '@bxgrandcentral/controls';

import React from 'react';
import { isNil } from 'lodash';
import { Wrapper } from 'components/controls/controls.styled';

type Props = {
    canCreate?: boolean;
};

export default function NeedPermission({ canCreate }: Props) {
    const message = (
        <>
            <p>You do not have permissions to create a new Legal Entity.</p>
            <p>
                Please contact&nbsp;
                <a href='mailto:BDGLegalEntityManagement@Blackstone.com'>BDGLegalEntityManagement@Blackstone.com</a>
                &nbsp;to request for access.
            </p>
        </>
    );
    if (!isNil(canCreate)) {
        return <Wrapper>{message}</Wrapper>;
    }

    return <WaitingIndicator id='spinner' isModalToShell={true} isVisible={true} />;
}
