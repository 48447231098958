import { useEffect, useState } from 'react';
import { ContextMenuData } from 'models/RequestsDashboard/ContextMenuData';
import useNavigation from './use-navigation';
import { AgGridReact } from 'ag-grid-react';
import { LegalEntityRequest } from 'models';

type Props = {
    gridRef: React.RefObject<AgGridReact<any>>;
};

export default function useContextMenu({ gridRef }: Props) {
    const { dashboardNewTabLink } = useNavigation();

    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuData>({
        contextMenuPositionX: 0,
        contextMenuPositionY: 0,
        RowData: undefined,
        popUpVisible: false,
    });

    useEffect(() => {
        const handleClickOutside = () => {
            if (gridRef.current) {
                closeContextMenu();
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleCellContextMenu = (params: any) => {
        params.event.preventDefault();
        setContextMenuPosition({
            contextMenuPositionX: params.event.clientX,
            contextMenuPositionY: params.event.clientY,
            RowData: params.data,
            popUpVisible: true,
        });
    };

    const closeContextMenu = () => {
        setContextMenuPosition({
            ...contextMenuPosition,
            popUpVisible: false,
        });
    };

    const ctrlKeyEvent = async (event: any, data?: LegalEntityRequest) => {
        if ((event.ctrlKey || event.metaKey) && event.button === 0) {
            var result = await dashboardNewTabLink(data);
            window.open(result);
            return;
        }
    };

    return { contextMenuPosition, handleCellContextMenu, closeContextMenu, ctrlKeyEvent };
}
