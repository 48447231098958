import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { RequestStepsView } from './LegalEntityCreationView/RequestStepsView';
import { convertToLocalDate } from 'Utilities/date';
import { generateLegalNameValue } from './utils';
import { isDevEnvironment } from 'Utilities/Environment';

export const SECTION_NAMES = {
    REQUEST: 'request',
    DOMESTIC_REGISTRATION: 'docPrep_domestic',
    CORPORATE_TRANSPARENCY: 'corporate_transparency',
    FOREIGN_REGISTRATION: 'docPrep_foreign',
    FOREIGN_REGISTRATION_UPDATE: 'foreign_registration_standalone',
    INFORMATION: 'legal_information',
    OWNERSHIP_INFORMATION: 'ownership',
    OWNERSHIP_INFORMATION_STANDALONE: 'ownership_standalone',
    TAX_CLASSIFICATION: 'tax_standalone',
    STAMPED: 'stamped',
};

export type SectionNameKeys = keyof typeof SECTION_NAMES;
export type SectionName = (typeof SECTION_NAMES)[SectionNameKeys];

export const REQUEST_CREATION_DEFAULT_VALUES = {
    [`${RequestStepsView.Request}`]: {
        businessDriverId: 1,
        businessUnitOId: 13934001,
        legalTypeId: 4,
        legalName: generateLegalNameValue(),
        structureType: 1,
        administeredBy: 3,
        isBlueprintEntity: false,
        isDisregardedEntity: false,
        lawFirmEntityOId: isDevEnvironment ? 96290 : 34790,
    },
    [`${RequestStepsView.Document_Preparation}`]: {
        domesticCountry: 1,
        domesticState: 1,
        domesticRegisteredAgent: 9,
        headquarterAddress: {
            countryId: 2,
            line1: 123,
        },
    },
    [`${RequestStepsView.Domestic_Registration}`]: {
        registrationNumber: 123,
        formationDate: '2022-10-11T00:00:00Z',
    },
    [`${RequestStepsView.Corporate_Transparency}`]: {
        isEntityOwnedByBlackstone: 2,
        reason: 'Looks good',
    },
    [`${RequestStepsView.Tax_Classification}`]: {
        isCtbElectionApproved: true,
        tax8832EffectiveDate: '2022-11-11T00:00:00.000Z',
        usStateTaxClassificationTypeId: 2,
        fatcaClassificationId: 109,
    },
    [`${RequestStepsView.Finance_Details}`]: {
        productNumber: '1234',
        costCenterNumber: '12312',
        billingProductNumber: '1234',
        billingCostCenterNumber: '12312',
        oracleLineOfBusinessId: 5,
        oracleLocationCodeId: 9,
        consolidatingStatusInOracleId: 3,
        treasuryLineOfBusinessId: 2,
        primaryLedgerId: 2057,
        investranId: 2,
        isAgisRequired: false,
        paymentsToWss: false,
    },
    [`${RequestStepsView.Ownership_Information}`]: {
        ownershipDetails: [
            {
                isDeleted: false,
                numberOfUnits: 12,
                ownerShip: '88',
                parentEntity: 271862,
                reportingType: 5,
                interestType: 8,
                interestUnit: 3,
                interestClass: 6,
            },
        ],
    },
    [`${RequestStepsView.Dissolution_Details}`]: {
        desiredDissolutionDate: convertToLocalDate(new Date()),
    },
    [`${RequestStepsView.Dissolution_Approval}`]: {
        approveDissolution: true,
        approveSoftClosure: true,
    },
    [`${RequestStepsView.Dissolution_Litigation}`]: {
        noOutstandingLegalMatters: CompletionStateKeys.Complete,
    },
    [`${RequestStepsView.Dissolution_Tax}`]: {
        allTaxRelated: CompletionStateKeys.Complete,
        taxInvoicesPreBilled: CompletionStateKeys.Complete,
        allTaxReporting: CompletionStateKeys.Complete,
        deregistrationAndNotification: CompletionStateKeys.Complete,
        taxAndLiquidationSteps: CompletionStateKeys.Complete,
    },
    [`${RequestStepsView.Dissolution_Finance}`]: {
        auditFinalBilling: CompletionStateKeys.Complete,
        auditFinalPaymentTimeline: CompletionStateKeys.Complete,
        cashPositionSettleReceivables: CompletionStateKeys.Complete,
        cashPositionDeterminePaydown: CompletionStateKeys.Complete,
        cashPositionProjectCash: CompletionStateKeys.Complete,
        liabilities: CompletionStateKeys.Complete,
        finalPaymentApproved: CompletionStateKeys.Complete,
        finalPaymentDistributed: CompletionStateKeys.Complete,
        intercompanyBalancesAreZero: CompletionStateKeys.Complete,
        bankAccountBalancesAreZero: CompletionStateKeys.Complete,
        treasuryAskedToCloseBankAccounts: CompletionStateKeys.Complete,
        trialBalanceCleared: CompletionStateKeys.Complete,
    },
    [`${RequestStepsView.Dissolution_Treasury}`]: {
        allAccountsClosed: CompletionStateKeys.Complete,
        outsideAccountsClosed: CompletionStateKeys.Complete,
        trialBalanceZero: CompletionStateKeys.Complete,
        noOpenIntercompanyBalances: CompletionStateKeys.Complete,
        closeInWSS: CompletionStateKeys.Complete,
    },
    [`${RequestStepsView.Dissolution_Legal}`]: {
        noOutstandingFundRelatedDuties: CompletionStateKeys.Complete,
        noActiveFundRegistrations: CompletionStateKeys.Complete,
    },
    [`${RequestStepsView.Dissolution_BDG}`]: {
        appSupport: CompletionStateKeys.Complete,
        registeredAgent: CompletionStateKeys.Complete,
        signatures: CompletionStateKeys.Complete,
        stateJurisdiction: CompletionStateKeys.Complete,
        finalConfirmations: CompletionStateKeys.Complete,
        updateDetails: CompletionStateKeys.Complete,
        dissolutionDate: convertToLocalDate(new Date()),
    },
};
