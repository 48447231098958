import { CREATE_ENTITY_STATE, DISSOLUTION_STATUS } from 'models/LegalEntityRequest/Workflow';
import { Capabilities, RequestStepsView } from '../../RequestStepsView';
import React, { useMemo } from 'react';

import InfoBlock from 'modules/LegalEntityCreation/Shared/InfoBlock';
import { useCreationViewContext } from '../../context/creation-view-context';
import useHasCapability from '../../hooks/use-has-capability';

export default function EntityStatusInfo() {
    const {
        state: { isEntityDissolved, selectedPanel, entityCreationWorkItem, legalEntityDissolutionParentWorkItem },
    } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const sectionsToShowDissolutionInProcess: string[] = [
        RequestStepsView.Foreign_Registration,
        RequestStepsView.Tax_Classification,
        RequestStepsView.Legal_Entity_Information,
        RequestStepsView.Finance_Details,
        RequestStepsView.Ownership_Information,
        RequestStepsView.Corporate_Transparency,
    ];

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const showEntityHasBeenDissolvedMessage = hasCapability(Capabilities.viewDissolutionRequest) && isEntityDissolved;

    const showEntityDissolutionInProgressMessage =
        isDissolutionInProcess && sectionsToShowDissolutionInProcess.includes(selectedPanel?.requestStepView || '');

    const showEntityHasBeenWithdrawnMessage = useMemo(() => {
        return (
            entityCreationWorkItem?.workItemState === CREATE_ENTITY_STATE.WITHDRAWN &&
            entityCreationWorkItem.workItemStatus === 'Canceled'
        );
    }, [entityCreationWorkItem]);

    return (
        <>
            {showEntityHasBeenDissolvedMessage && (
                <InfoBlock>This legal entity has been dissolved. No actions can be taken on this entity.</InfoBlock>
            )}
            {showEntityDissolutionInProgressMessage && (
                <InfoBlock>
                    This entity is currently in the process of being dissolved. No further actions can be taken at this
                    time.
                </InfoBlock>
            )}
            {showEntityHasBeenWithdrawnMessage && (
                <InfoBlock>This entity has been withdrawn. No further actions can be taken on this entity.</InfoBlock>
            )}
        </>
    );
}
