import {
    AdvancedFieldOptionItem,
    EntityMasterSearchRequestV4,
    FieldItem,
    IndexType,
    RequestedIndexSearch,
    RequestedSearchItem,
    SubfieldToSearch,
} from '../../../models/LegalEntitySearch/SearchRequest';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import { Expander, Select, TextField } from 'components/';
import { IEMSearchService, ILegalEntityService } from '../../../services/interfaces';
import { KeyValue, ReferenceData } from '../../../models/LegalEntityRequest/ReferenceData';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SearchResultItem, matchLegalEntitiesColumnDefs } from './model';
import { StackPanel, TextBlock, ThemeManager } from '@bxgrandcentral/controls';
import { updateSearchFilters, useAppContext } from 'context/app-context';

import { AgGridReact } from 'ag-grid-react';
import { ElasticSearchResult } from '../../../models/LegalEntitySearch/ElasticSearchResult';
import { EntityStatusId } from '../../../models/LegalEntity/EntityStatus';
import { EntitySubType } from '../../../models/LegalEntity/EntitySubType';
import { EntityType } from '../../../models/LegalEntity/EntityType';
import GridLoadingOverlay from 'modules/RequestsDashboard/components/Grid/components/GridLoadingOverlay';
import GridNoRowsOverlay from 'modules/RequestsDashboard/components/Grid/components/GridNoRowsOverlay';
import { MatchEntitiesDataProps } from 'context/model';
import { CellClickedEvent, ModelUpdatedEvent } from 'ag-grid-community';
import { ServiceLocator } from '@bxgrandcentral/shell';
import styled from 'styled-components';
import NewTabButton from '../Shared/NewTabButton';
import useNavigation from 'hooks/use-navigation';
import { LegalEntityRequest } from 'models';
import useContextMenu from 'hooks/use-context-menu';

let abortControllers: AbortController[] = [];

export const MatchLegalEntitiesView: React.FC<MatchEntitiesDataProps> = (props) => {
    const legalEntityRequest = ServiceLocator.container.resolve(ILegalEntityService);
    const legalEntitySearch = ServiceLocator.container.resolve(IEMSearchService);

    const [allowedBusinessUnits, setAllowedBusinessUnits] = useState<Pick<ReferenceData, 'BusinessUnit'>>();
    const [allSubBusinessUnits, setAllSubBusinessUnits] = useState<Pick<ReferenceData, 'SubBusinessUnit'>>();
    const [allowedSubBusinessUnits, setAllowedSubBusinessUnits] = useState<KeyValue[]>([]);
    const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const gridRef = useRef<AgGridReact>(null);

    const { dashboardNewTabLink } = useNavigation();

    const { contextMenuPosition, handleCellContextMenu, closeContextMenu, ctrlKeyEvent } = useContextMenu({ gridRef });

    function createSearchRequest(
        search: RequestedSearchItem[],
        filter: RequestedIndexSearch['searchFilters'] = []
    ): EntityMasterSearchRequestV4<SearchResultItem> {
        const allFilters: typeof filter = [
            {
                field: 'entityStatusId',
                value: [EntityStatusId.Active],
            },
            {
                field: 'entityTypeId',
                value: [EntityType.Organization],
            },
            {
                field: 'dataOwnerId',
                value: [2],
            },
            {
                field: 'entitySubtypes',
                value: [EntitySubType.LegalEntity],
            },
            ...filter,
        ];

        const searchRequest: EntityMasterSearchRequestV4<SearchResultItem> = {
            indexType: IndexType.MdmEntity,
            indexSearchRequest: {
                searchItems: search,
                searchFilters: allFilters,
            },
            resultOptions: {
                numberOfResults: 100,
                fieldsToReturn: [
                    'entityOId',
                    'primaryName',
                    'shortName',
                    'businessUnits',
                    'subBusinessUnits',
                    'hqAddressLine1',
                    'hqAddressLine2',
                    'hqAddressLine3',
                    'hqAddressLine4',
                    'hqAddressCity',
                    'hqAddressState',
                    'hqAddressCountry',
                    'hqAddressZipCode',
                    'businessUnitIds',
                    'subBusinessUnitIds',
                ],
                includeMetaData: false,
            },
        };

        return searchRequest;
    }

    const {
        state: { search: searchState },
        dispatch,
    } = useAppContext();
    const { businessUnitOId, subBusinessUnitOId, legalName, legalShortName, entityOid } = searchState;

    const apiRef = React.useRef({
        grid: undefined,
        column: undefined,
        api: undefined,
    });

    function SetBUFilters(search: RequestedSearchItem[], filter: RequestedIndexSearch['searchFilters'] | undefined) {
        let directBus: number[] = [];
        let directSubus: number[] = [];
        allowedBusinessUnits?.BusinessUnit?.forEach((bu) => {
            if (bu.IsDirect) {
                directBus.push(bu.Key);
            } else {
                const subBusinessUnits =
                    allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === bu.Key).map(
                        (bu) => bu.Key
                    ) || [];
                if (subBusinessUnits && subBusinessUnits.length > 0) {
                    subBusinessUnits.forEach((sbu) => {
                        directSubus.push(sbu);
                    });
                }
            }
        });
        if (filter && directBus.length > 0) {
            filter.push({
                field: 'businessUnitIds',
                isOr: true,
                value: directBus,
            });
        }
        if (filter && directSubus.length > 0) {
            filter.push({
                field: 'subBusinessUnitIds',
                isOr: true,
                value: directSubus,
            });
        }
    }

    function GetResultData<T>(response: ElasticSearchResult<T>): T[] {
        return response.searchResults.map((searchResult) => searchResult.data);
    }

    function FillHqAddress(results: SearchResultItem[]): SearchResultItem[] {
        results.forEach((result) => {
            result.hqAddress =
                GetLine(result.hqAddressLine1) +
                GetLine(result.hqAddressLine2) +
                GetLine(result.hqAddressLine3) +
                GetLine(result.hqAddressLine4) +
                GetLine(result.hqAddressCity) +
                GetLine(result.hqAddressState) +
                GetLine(result.hqAddressCountry) +
                GetLine(result.hqAddressZipCode);
        });
        return results;
    }

    function GetLine(line: string): string {
        return line ? ' ' + line : '';
    }
    function GetFieldItem(fieldName: string, subField?: SubfieldToSearch): FieldItem[] {
        let result: FieldItem[] = [
            {
                field: fieldName,
            },
        ];

        if (subField) {
            let advancedFieldOptionItem: AdvancedFieldOptionItem[] = [
                {
                    subFieldToSearch: subField,
                },
            ];
            result[0].advancedFieldOptions = advancedFieldOptionItem;
        }

        return result;
    }

    // On business unit change we have to update the allowed sub business unit options
    useEffect(() => {
        let filteredSubBusinessUnits: KeyValue[] = [];

        if (businessUnitOId) {
            filteredSubBusinessUnits =
                allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === businessUnitOId) || [];
        }

        // we need to reset to sub business unit selector if it has a value
        if (subBusinessUnitOId) {
            updateSearchFilters(dispatch, {
                ...searchState,
                subBusinessUnitOId: undefined,
            });
        }

        setAllowedSubBusinessUnits(filteredSubBusinessUnits);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitOId]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        const search: RequestedSearchItem[] = [];
        const filter: RequestedIndexSearch['searchFilters'] = [];
        if (legalName) {
            search.push({
                fields: GetFieldItem('primaryName'),
                queryString: legalName,
            });
        }
        if (legalShortName) {
            search.push({
                fields: GetFieldItem('shortName'),
                queryString: legalShortName,
            });
        }
        if (entityOid) {
            search.push({
                fields: GetFieldItem('entityOId', SubfieldToSearch.SearchDefaultField),
                queryString: entityOid.toString(),
            });
        }
        if (businessUnitOId) {
            filter.push({
                field: 'businessUnitIds',
                value: [businessUnitOId],
            });

            if (subBusinessUnitOId) {
                filter.push({
                    field: 'subBusinessUnitIds',
                    value: [subBusinessUnitOId],
                });
            } else {
                const filteredSubBusinessUnits =
                    allSubBusinessUnits?.SubBusinessUnit?.filter((item) => Number(item.ParentId) === businessUnitOId) ||
                    [];
                const subBusinessUnitFilter = filteredSubBusinessUnits?.map((bu) => bu.Key);
                const iDirect = allowedBusinessUnits?.BusinessUnit?.find((bu) => bu.Key === businessUnitOId)?.IsDirect;
                if (subBusinessUnitFilter?.length > 0 && !iDirect) {
                    filter.push({
                        field: 'subBusinessUnitIds',
                        value: subBusinessUnitFilter,
                    });
                }
            }
        } else {
            const businessUnitFilter = allowedBusinessUnits?.BusinessUnit?.map((bu) => bu.Key);
            if (businessUnitFilter && businessUnitFilter.length > 0) {
                SetBUFilters(search, filter);
            }
        }
        if (search.length) {
            setIsError(false);
            const abortController = new AbortController();

            if (abortControllers.length) {
                abortControllers[abortControllers.length - 1].abort();
            }
            gridRef.current?.api?.showLoadingOverlay();
            const request = createSearchRequest(search, filter);
            abortControllers.push(abortController);
            legalEntitySearch
                .searchLegalEntity(request, abortController)
                .then((result) => {
                    gridRef.current?.api?.hideOverlay();
                    const searchResults: SearchResultItem[] = GetResultData(result);
                    if (!searchResults.length) {
                        gridRef.current?.api?.showNoRowsOverlay();
                    }
                    setSearchResults(FillHqAddress(searchResults));
                })
                .catch((e: ErrorEvent) => {
                    if (!e?.message?.includes('The user aborted a request.')) {
                        setIsError(true);
                    }
                    gridRef.current?.api?.showNoRowsOverlay();
                    setSearchResults([]);
                });
        } else {
            setSearchResults([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitOId, entityOid, legalName, legalShortName, subBusinessUnitOId, gridRef.current?.api]);

    useEffect(() => {
        legalEntityRequest.GetUserBusinessUnits().then((response) => {
            setAllowedBusinessUnits(response);
        });
        return () => {
            abortControllers = [];
        };
    }, [legalEntityRequest]);

    useEffect(() => {
        legalEntityRequest.GetUserSubBusinessUnits().then((response) => {
            setAllSubBusinessUnits(response);
        });
    }, [legalEntityRequest]);

    const [numberOfRows, setNumberOfRows] = useState(searchResults?.length || 0);

    const onGridReady = (params: any) => {
        apiRef.current.grid = params.api;
        apiRef.current.column = params.columnApi;
        apiRef.current.api = params.api;
        params.api.sizeColumnsToFit();
    };

    const theme = ThemeManager.activeTheme.name === 'Dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    const resizeColumns = () => {
        (apiRef.current?.api as any).sizeColumnsToFit();
    };

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent) => {
            const rowCount = api.getModel().getRowCount();
            if (numberOfRows !== rowCount) {
                setNumberOfRows(rowCount);
            }
        },
        [numberOfRows]
    );

    const noRowsMessage =
        businessUnitOId ||
        entityOid ||
        legalName?.length ||
        legalShortName?.length ||
        subBusinessUnitOId ||
        (apiRef.current?.api as any)?.isAnyFilterPresent()
            ? 'There are no matching entities.'
            : '';

    return (
        <Layout>
            <Expander
                header='Legal Entity Information'
                content={
                    <DoubleColumnLayout>
                        <StackPanel itemGap={SPACING.MD}>
                            <TextField
                                label='Entity ID'
                                canUndo={false}
                                value={entityOid?.toString()}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        entityOid: newValue,
                                    });
                                }}
                                isEditable
                            />
                            <Select
                                label='Business Unit'
                                itemsSource={allowedBusinessUnits?.BusinessUnit || []}
                                value={businessUnitOId}
                                isEditable
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        businessUnitOId: newValue,
                                    });
                                }}
                            />
                            {!!allowedSubBusinessUnits.length && (
                                <Select
                                    label='Sub-Business Unit'
                                    itemsSource={allowedSubBusinessUnits}
                                    value={subBusinessUnitOId}
                                    onValueChanged={(newValue) => {
                                        updateSearchFilters(dispatch, {
                                            ...searchState,
                                            subBusinessUnitOId: newValue,
                                        });
                                    }}
                                    isEditable
                                />
                            )}
                        </StackPanel>
                        <StackPanel itemGap={SPACING.MD}>
                            <TextField
                                label='Legal Entity Name'
                                canUndo={false}
                                value={legalName}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        legalName: newValue,
                                    });
                                }}
                                isEditable
                            />
                            <TextField
                                label='Legal Entity Short Name'
                                canUndo={false}
                                value={legalShortName}
                                onValueChanged={(newValue) => {
                                    updateSearchFilters(dispatch, {
                                        ...searchState,
                                        legalShortName: newValue,
                                    });
                                }}
                                isEditable
                            />
                        </StackPanel>
                    </DoubleColumnLayout>
                }
            />
            <TextBlock
                text='Matching Entities'
                styleName='heading1Style'
                margin={`${SPACING.XL}px 0 ${SPACING.MD}px 0`}
            />
            <div className={theme}>
                <AgGridReact
                    rowData={searchResults}
                    ref={gridRef}
                    onCellClicked={({ data, event }: CellClickedEvent<LegalEntityRequest>) => {
                        ctrlKeyEvent(event, data);
                    }}
                    columnDefs={matchLegalEntitiesColumnDefs}
                    onGridReady={onGridReady}
                    onFirstDataRendered={resizeColumns}
                    loadingOverlayComponent={GridLoadingOverlay}
                    onModelUpdated={onModelUpdated}
                    rowClass='custom-row'
                    noRowsOverlayComponent={GridNoRowsOverlay}
                    noRowsOverlayComponentParams={{ noRowsMessage, isError }}
                    onCellContextMenu={handleCellContextMenu}
                    suppressAsyncEvents={true}
                />
                {contextMenuPosition?.popUpVisible && (
                    <NewTabButton
                        closeContextMenu={closeContextMenu}
                        newTabLink={dashboardNewTabLink}
                        contextMenu={contextMenuPosition}
                    />
                )}
            </div>
        </Layout>
    );
};

const Layout = styled.div`
    display: grid;
    grid-template-rows: auto auto 1fr;
    padding: ${SPACING.XL}px;
`;
