import { validateNumberField, validateRequiredField } from './utils';

import { YesNoStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import useHasUsRegistration from '../LegalEntityCorporateTransparency/hooks/use-has-us-registration';
import { useLegalEntityCorporateTransparency } from '../context/Provider';

const bxOwnershipPercentageValidationMessage =
    'Value must be between 0 (including) and 100 (not including). If BX owns 100%, please select Yes to the above question Is this entity wholly owned by Blackstone Inc.';

export const bxHasEnoughOwnershipPercent = (ownershipPercent: any) => {
    if (ownershipPercent === undefined) {
        return false;
    }

    return Number(ownershipPercent) >= 75 && Number(ownershipPercent) < 100;
};

export const ctaStatusReasonMaxLength = 2500;

type Props = {
    mode: 'Create' | 'Edit' | 'ReadOnly';
};

export default function useCorporateTransparencyValidation({ mode }: Props) {
    const {
        state: {
            data: {
                values: {
                    isEntityOwnedByBlackstone,
                    bxOwnershipPercentage,
                    canBlackstoneControlOwnershipTransfer,
                    canBlackstoneEffectuateReorganization,
                    corporateTransparencyStatus,
                    reason,
                    CTAReviewReason,
                },
                storedValues: { corporateTransparencyStatus: storedCorporateTransparencyStatus },
            },
            version,
        },
        dispatch,
    } = useLegalEntityCorporateTransparency();

    const hasUsRegistration = useHasUsRegistration();

    useEffect(() => {
        const bxHasEnoughOwnership = bxHasEnoughOwnershipPercent(bxOwnershipPercentage);

        updateValidation(dispatch, {
            isEntityOwnedByBlackstone: validateRequiredField(isEntityOwnedByBlackstone, {
                isRequired: hasUsRegistration,
            }),
            bxOwnershipPercentage:
                Number(bxOwnershipPercentage) === 100
                    ? bxOwnershipPercentageValidationMessage
                    : validateNumberField({
                          value: bxOwnershipPercentage?.toString(),
                          minimumLength: 1,
                          isRequired: isEntityOwnedByBlackstone === YesNoStateKeys.No,
                          allowDecimals: true,
                          maxDecimals: 5,
                          isPercentage: true,
                          percentageValidationMessage: bxOwnershipPercentageValidationMessage,
                      }),
            canBlackstoneControlOwnershipTransfer: validateRequiredField(canBlackstoneControlOwnershipTransfer, {
                isRequired: isEntityOwnedByBlackstone === YesNoStateKeys.No && bxHasEnoughOwnership,
            }),
            canBlackstoneEffectuateReorganization: validateRequiredField(canBlackstoneEffectuateReorganization, {
                isRequired: isEntityOwnedByBlackstone === YesNoStateKeys.No && bxHasEnoughOwnership,
            }),
            reason: validateRequiredField(reason, { maxLength: ctaStatusReasonMaxLength }),
            corporateTransparencyStatus: validateRequiredField(corporateTransparencyStatus, {
                isRequired: mode === 'Edit',
            }),
            CTAReviewReason: validateRequiredField(CTAReviewReason, {
                isRequired: mode === 'Edit' && corporateTransparencyStatus !== storedCorporateTransparencyStatus,
            }),
        });
    }, [
        dispatch,
        isEntityOwnedByBlackstone,
        bxOwnershipPercentage,
        canBlackstoneControlOwnershipTransfer,
        canBlackstoneEffectuateReorganization,
        corporateTransparencyStatus,
        storedCorporateTransparencyStatus,
        reason,
        CTAReviewReason,
        version,
        mode,
        hasUsRegistration,
    ]);
}
