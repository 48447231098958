import { MessageBoxType } from './components/MessageBox';
import { ReactNode } from 'react';
import { StateVariant } from 'Utilities/Layout';

export type GlobalDialogProps = {
    variant?: StateVariant;
    content?: ReactNode;
    title?: string;
    isConfirmationDialog?: boolean;
    okButtonLabel?: string;
    cancelButtonLabel?: string;
    buttonAction?: () => void;
};
export class GlobalState {
    static ShowMessageBox: (
        type: MessageBoxType,
        message: string | ReactNode,
        buttonTitle?: string,
        dialogTitle?: string,
        buttonAction?: () => void,
    ) => void;
    static openDialog: (props: GlobalDialogProps) => Promise<boolean>;
}
