import { DISSOLUTION_STATE, DISSOLUTION_STATUS } from 'models/LegalEntityRequest/Workflow';
import { DateTimeField, Debugger, Expander, Footer, Form, Text, TextField } from 'components';
import { DoubleColumnLayout, GridLayout, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import ActionPanel from './components/ActionPanel/ActionPanel';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import InfoBlock from 'modules/LegalEntityCreation/Shared/InfoBlock';
import { LegalEntityDissolutionDetailsProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { convertToLocalDate } from 'Utilities/date';
import { isNil } from 'lodash';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionDetailsValidation from 'modules/LegalEntityCreation/validation/use-dissolution-details-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionDetails } from '../../context/Provider';

export default function LegalEntityDissolutionDetails(props: LegalEntityDissolutionDetailsProps) {
    const {
        state: {
            legalEntityOId,
            isEntityDissolvable,
            legalEntityDissolutionParentWorkItem,
            isEntityDissolved,
            noPendingSections,
        },
    } = useCreationViewContext();

    const context = useLegalEntityDissolutionDetails();
    const {
        getValue,
        setValue,
        state: {
            data: {
                values: { billingOracleNumber, billingProductNumber, billingCostCenterNumber, savedBy, savedAt },
            },
        },
    } = context;

    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionParentWorkItem,
        taskType: 'SubmitRequestType',
        saved: {
            savedBy,
            savedAt,
        },
    });

    const { oracleNumber, productNumber, costCenterNumber } = noPendingSections.FinanceInformation;

    useDissolutionDetailsValidation();

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const onDateChanged = (value?: Date | string | undefined) => {
        setValue('desiredDissolutionDate', convertToLocalDate(value));
    };

    const billingFieldsRequired = useMemo(
        () => !!(billingOracleNumber || billingProductNumber || billingCostCenterNumber),
        [billingOracleNumber, billingProductNumber, billingCostCenterNumber]
    );

    const isBillingCodesMatchInitialValue = useMemo(
        () =>
            costCenterNumber === billingCostCenterNumber &&
            ((isNil(oracleNumber) && isNil(billingOracleNumber)) || oracleNumber?.toString() === billingOracleNumber) &&
            productNumber === billingProductNumber,
        [
            costCenterNumber,
            billingCostCenterNumber,
            oracleNumber,
            billingOracleNumber,
            productNumber,
            billingProductNumber,
        ]
    );

    const showSameCodeInfoBlock =
        ['Edit', 'Create'].includes(props.mode) && billingFieldsRequired && isBillingCodesMatchInitialValue;

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Details} />
            {!isEntityDissolvable && !isDissolutionInProcess && !isEntityDissolved ? (
                <InfoBlock>
                    There are open child work items / update requests associated with this entity. For this entity to be
                    dissolved, the open child work items/update requests must be closed. Please contact
                    bxti-datagovernance@blackstone.com for assistance.
                </InfoBlock>
            ) : null}
            <Form>
                <>
                    {props.mode === 'Readonly' && props.isWorkItemWasCreated && (
                            <Text
                                horizontalAlignment='center'
                                verticalAlignment='center'
                                textAlignment='center'
                                variant='success'>
                                <p>The dissolution request has been successfully initiated.</p>
                                <p>Entity Information updates are no longer allowed while the entity is in the dissolving state.</p>
                                <strong>It takes some time to process it. Please click on refresh to continue!</strong>
                            </Text>
                    )}            
                    <Expander
                        header='Legal Entity Dissolution Details'
                        requestStep={RequestStepsView.Dissolution_Details}
                        content={
                            <StackPanel itemGap={SPACING.SM} margin={`${SPACING.XL}px 0 0 0`}>
                                <GridLayout columns='2fr 1fr'>
                                    <StackPanel>
                                        <TextBlock text='Desired Dissolution Date' margin={`${SPACING.XS}px 0 0 0`} />
                                    </StackPanel>
                                    <DateTimeField
                                        {...getValue('desiredDissolutionDate')}
                                        onValueChanged={onDateChanged}
                                        isPendingChange={false}
                                    />
                                </GridLayout>
                            </StackPanel>
                        }
                    />
                    <Expander
                        header='Add Optional New Billing Codes'
                        requestStep={RequestStepsView.Dissolution_Details}
                        content={
                            <>
                                <StackPanel margin={`0 0 ${SPACING.XL}px 0`}>
                                    <Text
                                        horizontalAlignment='center'
                                        verticalAlignment='center'
                                        textAlignment='center'>
                                        <p>
                                            Add a new Billing Oracle Code and/or Billing Project Code in the case of any
                                            future expenses incurred.
                                        </p>
                                    </Text>
                                </StackPanel>
                                <DoubleColumnLayout>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <TextField
                                            label='Oracle Number'
                                            value={oracleNumber?.toString()}
                                            isPendingChange={false}
                                            isReadOnly
                                        />
                                        <TextField
                                            label='Product Number'
                                            value={productNumber?.toString()}
                                            isPendingChange={false}
                                            isReadOnly
                                        />
                                        <TextField
                                            label='Cost Center Number'
                                            value={costCenterNumber?.toString()}
                                            isPendingChange={false}
                                            isReadOnly
                                        />
                                    </StackPanel>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <TextField
                                            label='Billing Oracle Number'
                                            {...getValue('billingOracleNumber')}
                                            isPendingChange={false}
                                            isRequired={billingFieldsRequired}
                                        />
                                        <TextField
                                            label='Billing Product Number'
                                            {...getValue('billingProductNumber')}
                                            isPendingChange={false}
                                            maxLength={4}
                                            isRequired={billingFieldsRequired}
                                        />
                                        <TextField
                                            label='Billing Cost Center Number'
                                            {...getValue('billingCostCenterNumber')}
                                            maxLength={5}
                                            isPendingChange={false}
                                            isRequired={billingFieldsRequired}
                                        />
                                        <StackPanel margin={`${SPACING.MD}px 0 0 0`}>
                                            <TextField
                                                label='Billing Project Code'
                                                labelToolTip='Enter a new billing project to replace the existing billing project code.'
                                                {...getValue('billingProjectCode')}
                                                maxLength={6}
                                                isPendingChange={false}
                                            />
                                        </StackPanel>
                                    </StackPanel>
                                </DoubleColumnLayout>
                                {showSameCodeInfoBlock && (
                                    <StackPanel>
                                        <InfoBlock>
                                            Please note that Billing Codes match Original Oracle Codes. Please change
                                            the codes if you would like to enter a new code for the dissolving entity in
                                            the case of any future expenses.
                                        </InfoBlock>
                                    </StackPanel>
                                )}
                            </>
                        }
                    />
                    <Expander
                        header='Request Notes'
                        content={
                            <div className='text-area-hide-scrollbar'>
                                <TextField
                                    minHeight={120}
                                    maxHeight={240}
                                    variant='default'
                                    textWrapping='wrap'
                                    padding={`${SPACING.XS}px 0`}
                                    canUndo={false}
                                    {...getValue('requestNotes')}
                                    isPendingChange={false}
                                />
                            </div>
                        }
                    />
                    <DissolutionRejectReason
                        workflowType={WorkflowType.LegalEntityDissolutionParentWorkflowType}
                        currentWorkItemState={props.workItem?.workItemState}
                        workItemStateToExclude={DISSOLUTION_STATE.AWAITING_FOR_CHILD_APPROVAL}
                    />
                    <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
                </>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={legalEntityOId} {...props} />
                </Footer>
            )}
        </>
    );
}
