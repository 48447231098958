import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { isEmpty } from '../../../Utilities/Validations';
import { isNil } from 'lodash';

export const VALIDATION_MESSAGES = {
    REQUIRED_FIELD: 'Field is required',
    INVALID_NUMBER: 'Field is not a valid number',
    COMBINED_ERROR: 'Logical issues',
    INVALID_FUTURE_DATE: 'Date must not be a future date',
    INVALID_VALUE_LENGTH: (minimumLength: number) => `Field must contain ${minimumLength} digits`,
    UNIQUE_TAX_TYPE: 'There can only be one unique Tax ID Type per Tax ID',
    UNIQUE_TAX_CODE: 'Tax ID Values must be unique across Tax ID Types',
    DOCUMENT_SERVICE_ERROR: 'Error occured while loading documents',
    APPOINTMENT_AND_RESIGNATION_DATE: 'Resignation Date cannot be prior to Appointment Date',
    SAME_THIRD_PARTY_TITLE: 'This title has already been used for this third party',
    GENERAL_TYPE_REQUIRED: 'This entity’s legal type requires 1 general partner',
    INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER:
        'Parent entity with interest type Sole Member should be the only parent entity',
    TOTAL_OWNERSHIP_EXCEEDS: 'Total Ownership % cannot be greater than 100%',
    LEGAL_TYPE_TRUST_DOCUMENTS_MISSING: 'Trust Agreement, Trust Deed or Resolution document missing',
    OWNERSHIP_INFORMATION_DOCUMENTS_MISSING: 'Bylaws, Resolution or Consent to Use of Name document missing',
    REGISTER_OF_MEMBER_DOCUMENT_MISSING: 'Register of Member document missing',
    EMPTY_OWNERSHIP_DETAILS: "Ownership Details can't be empty",
    INVALID_UNIQUE_OWNERSHIP_PARENT: 'This entity has already been used as an owner for this entity',
    DOCUMENTS_INVALID_MULTIPLE_DOCUMENTS: 'Only one document per document type can be uploaded',
    DOCUMENTS_MANDATORY_FIELD: 'Document upload is required. Please select at least one file',
    DOCUMENTS_REQUIRED_FIELDS_ARE_MISSING: 'Required fields are missing',
    DOCUMENTS_STAMPED_DOCUMENT_MISSING: 'A stamped document is required for the following types',
    DOCUMENTS_REQUIRED_FIELDS_ARE_NOT_SET_PROPERLY: 'Required fields are not set properly',
    MUST_COMPLETED: 'Field must be Completed',
    PERCENTAGE_RANGE: 'Please enter in a valid percentage number.',
    TOO_MUCH_DECIMALS: (maxDecimals: number) => `Maximum ${maxDecimals} decimals allowed`,
    TOO_MUCH_CHARACTER: (maxDecimals: number) => `Maximum ${maxDecimals} character allowed`,
} as const;

export const DISSOLUTION_REJECT_DIALOG_MESSAGE =
    'Warning: When you reject, the dissolution request will be sent back to the requestor. All approval processes will stop and new approvals will be required after re-submission. Please coordinate with other approvers if your rejection reason is related to the approvals and not the dissolution request.';

const {
    REQUIRED_FIELD,
    INVALID_NUMBER,
    INVALID_FUTURE_DATE,
    INVALID_VALUE_LENGTH,
    PERCENTAGE_RANGE,
    TOO_MUCH_DECIMALS,
    TOO_MUCH_CHARACTER,
} = VALIDATION_MESSAGES;

type validateNumberProps = {
    value?: string;
    minimumLength?: number;
    isRequired?: boolean;
    allowDecimals?: boolean;
    maxDecimals?: number;
    isPercentage?: boolean;
    percentageValidationMessage?: string;
};

function generateMaxDecimalRegex(maxDecimals: number) {
    return new RegExp(`^\\d+(\\.\\d{1,${maxDecimals}})?$`);
}

export function validateNumberField({
    value,
    minimumLength,
    isRequired = true,
    allowDecimals = false,
    maxDecimals = 0,
    isPercentage = false,
    percentageValidationMessage = PERCENTAGE_RANGE,
}: validateNumberProps) {
    if (isEmpty(value)) {
        return isRequired ? REQUIRED_FIELD : undefined;
    }

    if (allowDecimals && !/^[+]?\d+(\.\d+)?$/.test(value)) {
        return INVALID_NUMBER;
    }

    if (allowDecimals && maxDecimals && !generateMaxDecimalRegex(maxDecimals).test(value)) {
        return TOO_MUCH_DECIMALS(maxDecimals);
    }

    if (!allowDecimals && !/^[0-9]+$/.test(value)) {
        return INVALID_NUMBER;
    }

    if (isPercentage && (Number(value) < 0 || Number(value) > 100)) {
        return percentageValidationMessage;
    }

    return minimumLength && value.length < minimumLength ? INVALID_VALUE_LENGTH(minimumLength) : undefined;
}

type RequiredFieldOptions = {
    isRequired?: boolean;
    message?: string;
    maxLength?: number;
};

export function validateRequiredField(
    value: Date | string | number | boolean | undefined,
    options?: RequiredFieldOptions
) {
    const { isRequired = true, message = REQUIRED_FIELD, maxLength } = options ?? {};
    if (isRequired && isNil(value)) {
        return message;
    }

    if (maxLength && (typeof value === 'string' || value instanceof String) && value.length > maxLength) {
        return TOO_MUCH_CHARACTER(maxLength);
    }
}

type DateTimeFieldOptions = {
    isOptionalWhen?: boolean;
    canBeFutureDate?: boolean;
    isRequired?: boolean;
    errorMessage?: string;
};

export function validateDateTimeField(value: string | Date | undefined | null, options: DateTimeFieldOptions) {
    const { isOptionalWhen = true, canBeFutureDate = true, isRequired = false, errorMessage = '' } = options;

    if (isNil(value) && !isOptionalWhen) {
        return REQUIRED_FIELD;
    }
    if (isRequired) {
        return errorMessage;
    }
    if (value && new Date(value) > new Date() && !canBeFutureDate) {
        return INVALID_FUTURE_DATE;
    }
}

export const isEqual = (valueA: any, valueB: any) => {
    let parsedValueA = valueA ?? undefined;
    let parsedValueB = valueB ?? undefined;

    if (parsedValueA?.trim) {
        parsedValueA = parsedValueA.trim();
    }

    if (parsedValueB?.trim) {
        parsedValueB = parsedValueB.trim();
    }

    return parsedValueA === parsedValueB;
};

export function validateCompleted(
    value: number | undefined,
    options?: RequiredFieldOptions,
    acceptableValues?: CompletionStateKeys[]
) {
    const { isRequired = true, message = REQUIRED_FIELD } = options ?? {};

    if (isRequired && isNil(value)) {
        return message;
    }

    if (isRequired && !acceptableValues?.length && value !== CompletionStateKeys.Complete) {
        return VALIDATION_MESSAGES.MUST_COMPLETED;
    }

    if (isRequired && acceptableValues?.length && value && !acceptableValues.includes(value)) {
        return VALIDATION_MESSAGES.MUST_COMPLETED;
    }
}
