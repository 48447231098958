import { LegalEntityDissolutionFinanceData, LegalEntityDissolutionFinanceProps } from './model';
import React, { useCallback } from 'react';

import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import LegalEntityDissolutionFinance from './LegalEntityDissolutionFinance';
import { LegalEntityDissolutionFinanceProvider } from '../../context/Provider';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDissolutionFinanceSection(props: LegalEntityDissolutionFinanceProps) {
    const calculateDefaultValues = useCallback(
        (data?: LegalEntityDissolutionFinanceData) => ({
            isAuditRequired: true,
            auditFinalBilling: CompletionStateKeys.Incomplete,
            auditFinalPaymentTimeline: CompletionStateKeys.Incomplete,
            cashPositionSettleReceivables: CompletionStateKeys.Incomplete,
            cashPositionDeterminePaydown: CompletionStateKeys.Incomplete,
            cashPositionProjectCash: CompletionStateKeys.Incomplete,
            liabilities: CompletionStateKeys.Incomplete,
            finalPaymentApproved: CompletionStateKeys.Incomplete,
            finalPaymentDistributed: CompletionStateKeys.Incomplete,
            intercompanyBalancesAreZero: CompletionStateKeys.Incomplete,
            treasuryAskedToCloseBankAccounts: CompletionStateKeys.Incomplete,
            bankAccountBalancesAreZero: CompletionStateKeys.Incomplete,
            trialBalanceCleared: CompletionStateKeys.Incomplete,
        }),
        []
    );

    return (
        <LegalEntityDissolutionFinanceProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Finance}>
            <LegalEntityDissolutionFinance {...props} />
        </LegalEntityDissolutionFinanceProvider>
    );
}
