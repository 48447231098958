import { Button, FontIcon, StackPanel, TextBlock, ThemeManager } from '@bxgrandcentral/controls';
import { LegalEntityRequest } from 'models';
import { ContextMenuData } from 'models/RequestsDashboard/ContextMenuData';
import React from 'react';
import { SPACING } from 'Utilities/Layout';

type Props = {
    contextMenu: ContextMenuData;
    newTabLink: (request?: LegalEntityRequest) => Promise<string | undefined>;
    closeContextMenu: () => void;
};

export default function NewTabButton({ contextMenu, newTabLink, closeContextMenu }: Props) {
    const {
        activeTheme: {
            colors: { backgroundColor },
        },
    } = ThemeManager;

    const onClick = async () => {
        var result = await newTabLink(contextMenu.RowData);
        window.open(result);
        closeContextMenu();
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: contextMenu.contextMenuPositionY,
                left: contextMenu.contextMenuPositionX,
                zIndex: 1000,
            }}>
            <Button
                styleOverrides={{ alignSelf: 'center' }}
                padding={`${SPACING.XS}px ${SPACING.MD}px`}
                onClick={() => onClick()}
                width={180}
                backgroundColor={backgroundColor}
                content={
                    <StackPanel orientation='horizontal' verticalAlignment='center' itemGap={SPACING.XXS}>
                        <FontIcon fontSize={16} iconName='OpenInNewTab' />
                        <TextBlock text='Open in new tab' fontSize={12} />
                    </StackPanel>
                }
            />
        </div>
    );
}
