import { TextBlock, ThemeManager } from '@bxgrandcentral/controls';

import React from 'react';

type Props = {
    limit: number;
    length?: number;
};
export default function TextLengthStatus({ limit, length = 0 }: Props) {
    const {
        activeTheme: {
            colors: { systemRedColor, systemGreenColor },
        },
    } = ThemeManager;

    const foregroundColor = length > limit ? systemRedColor : systemGreenColor;

    const message = `${length} of ${limit} characters used`;

    return (
        <TextBlock
            fontSize={13}
            foregroundColor={foregroundColor}
            text={message}
            textAlignment='right'
            styleOverrides={{ width: '100%', display: 'inline-block' }}
        />
    );
}
