import { Button, Dialog, FontIcon, Grid, TextBlock } from '@bxgrandcentral/controls';
import React, { ReactNode, useState } from 'react';

import { GlobalState } from '../GlobalState';

export type MessageBoxType = 'ERROR' | 'WARNING' | 'INFO';

export const MessageBox: React.FC = () => {
    const [message, setMessage] = useState<string | ReactNode>('');
    const [messageBoxType, setMessageBoxType] = useState<MessageBoxType>('ERROR');
    const [buttonContent, setButtonContent] = useState<string>('');
    const [dialogTitle, setDialogTitle] = useState<string | undefined>();
    const [buttonAction, setButtonAction] = useState<(() => void) | undefined>();

    GlobalState.ShowMessageBox = (boxType, boxMessage = '', buttonTitle = 'OK', dialogTitle, buttonAction) => {
        setMessage(boxMessage);
        setMessageBoxType(boxType);
        setButtonContent(buttonTitle);
        setDialogTitle(dialogTitle);
        setButtonAction(() => buttonAction);
    };

    return (
        <Dialog
            title={dialogTitle ?? messageBoxType}
            isOpen={message !== ''}
            width='500px'
            onDialogClosed={() => setMessage('')}>
            <Grid columnDefinitions='50px *' rowDefinitions='* auto' padding={20}>
                <FontIcon
                    gridRow={0}
                    gridColumn={0}
                    iconName={
                        messageBoxType === 'ERROR'
                            ? 'StatusErrorFull'
                            : messageBoxType === 'WARNING'
                            ? 'WarningSolid'
                            : 'InfoSolid'
                    }
                    foregroundColor={
                        messageBoxType === 'ERROR' ? 'Red' : messageBoxType === 'WARNING' ? 'Gold' : 'DodgerBlue'
                    }
                    fontSize={36}
                    verticalAlignment='center'
                />
                <TextBlock gridRow={0} gridColumn={1} textWrapping='wrap'>
                    {message}
                </TextBlock>
                <Button
                    gridRow={1}
                    gridColumnSpan={2}
                    horizontalAlignment='center'
                    content={buttonContent}
                    onClick={() => {
                        if (buttonAction) {
                          buttonAction(); 
                        }
                        setMessage('');
                      }}
                    margin='20px 0 0 0'
                />
            </Grid>
        </Dialog>
    );
};
